import { withAuthenticationRequired } from '@auth0/auth0-react'
import { PageProps } from 'gatsby'
import Layout from 'manage-tritag/components/layout'
import EditForm from 'manage-tritag/components/pages/draw-builder/edit-form'

const DrawBuilderEditPage = (props: PageProps) => {
  return (
    <Layout label1="Draw Builder" label2="Update" url="/draw-builder">
      <EditForm {...props} />
    </Layout>
  )
}

export default withAuthenticationRequired(DrawBuilderEditPage)
